

.App {
  text-align: center;
  font-family: Optima;
  background-color: #232427;
  color:white ;
  transition: color 1s linear;



}
.yellow{
  color: rgb(255, 187, 0);

}
.yellow>*>*>.link{
    color: rgb(255, 187, 0);

  transition: color 1s linear;


}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #232427;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(9px + 1vmin);
  padding: 3vh 12vw;
  /* animation: spin 200s linear infinite; */

}
.eightball{
  font-size: 2em;
  transition: rotate 1s;
  transform: rotate(360deg)
}
.link{
  color: white;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.title{
  font-weight: bold; 
  /* font-style: italic; */

  /* font-size: 1.2em; */
}
.subtitle{
  z-index: 0;
  opacity: .3;
  /* animation: spin 1000s linear infinite; */
  cursor: pointer;
}

.bio{
/* font-size: 16pt; */
line-height: 1.2;
max-width: 360pt;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
